/**
 * set default values for dates
 * salary specific - it counts for halfmonths - first half and second half
 * return Date() 1 - 15 or 16 - last month day
 */
function datesHalfMonth() {
  let date_begin, date_end;
  const d = new Date();
  let nowDay = d.getDate();
  let nowMonth = d.getMonth();
  let nowYear = d.getFullYear();
  if (nowDay > 16) {
    date_begin = new Date(d.setDate(1));
    date_end = new Date(d.setDate(15));
  } else {
    date_begin = new Date(nowYear, nowMonth - 1, 16);
    date_end = new Date(nowYear, nowMonth - 1, new Date(nowYear, nowMonth, 0).getDate());
  }

  return { date_begin, date_end };
}

export { datesHalfMonth };
