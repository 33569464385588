/**
 *
 * @param {date_begin} дата начала периода
 * @param {date_end} дата окончания периода
 * @param {date_max_interval} максимальный интервал между датами
 * @returns {date_begin, date_end} объекты Date()
 *
 * check both dates validity, interval between them
 * return Date() objects
 */
function datesCheck(date_begin, date_end, date_max_interval = 0) {
  // if date_begin not date set Today
  date_begin = new Date(date_begin);
  if (date_begin == "Invalid Date") date_begin = new Date();

  // if date_end not date set date_begin
  date_end = new Date(date_end);
  if (date_end == "Invalid Date") date_end = date_begin;

  // if begin higher, then end, set them equal
  if (date_begin > date_end) date_end = date_begin;

  // check for interval is not more, than date_max_interval days
  if (date_max_interval && new Date(new Date(date_begin).setDate(date_begin.getDate() + date_max_interval)) < date_end)
    date_end = new Date(new Date(date_begin).setDate(date_begin.getDate() + date_max_interval));

  return { date_begin, date_end };
}

export { datesCheck };
