<template>
  <div class="high" @click="$refs.date.focus()">
    {{ label }}
    <br v-if="labelPosition !== 'left'" />
    <input
      class="date-input"
      ref="date"
      :value="val"
      type="date"
      @change="$emit('change', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: "DateSelector",
  props: { val: String, label: String, labelPosition: String },
};
</script>

<style lang="scss" scoped>
.date-input {
  margin-top: 12px;
  padding-left: 12px;
}
</style>
